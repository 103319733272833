import React, { useEffect } from "react"
import { Contact, About, Presentation, Footer } from "src/modules/Main"
import { config } from "@fortawesome/fontawesome-svg-core"
import "@fortawesome/fontawesome-svg-core/styles.css"
import { fetchCarousel } from "src/redux/carousel"
import { useDispatch } from "react-redux"
import { fetchPrices } from "src/redux/prices"
import { setSystem } from "src/redux/system"
import { Auth } from "aws-amplify"

export default () => {
    config.autoAddCss = false
    const dispatch = useDispatch()
    useEffect(() => {
        const test = async () => {
            console.log(await Auth)
        }
        test()
        dispatch(fetchCarousel())
        dispatch(fetchPrices())
        dispatch(setSystem())
    }, [])
    return (
        <>
            <Presentation />
            <About />
            <Contact />
            <Footer />
        </>
    )
}
